import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChanged = (lang) => {
      setSelectedLanguage(lang);
    };

    i18n.on('languageChanged', handleLanguageChanged);

    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [i18n]);

  const changeLanguage = (langCode) => {
    i18n.changeLanguage(langCode);
    setSelectedLanguage(langCode); // This line might be redundant due to the useEffect hook, but ensures consistency
  };

  return (
    <footer className="mt-auto py-3 custom-footer">
      <div className="container d-flex justify-content-between align-items-center">
        <span className="text-muted">© {new Date().getFullYear()} Tram & Henrik</span>
        <select
          className="form-control form-control-sm w-auto"
          value={selectedLanguage}
          onChange={(e) => changeLanguage(e.target.value)}
          style={{ minWidth: "120px" }}
        >
          <option value="no">🇳🇴 Norsk</option>
          <option value="vi">🇻🇳 Tiếng Việt</option>
          <option value="en">🇬🇧 English</option>
          <option value="de">🇩🇪 Deutsch</option>
        </select>
      </div>
    </footer>
  );
};

export default Footer;

