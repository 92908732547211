import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import { useAuth } from './AuthContext';

const HiddenImages = () => {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(-1);
  const { t } = useTranslation();

  const { isAdmin, get, patch} = useAuth();

  useEffect(() => {
    get("/api/uploads/hidden")
      .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw Error(res.statusText);
      })
      .then(data => setImages(data.images))
      .catch(err => console.error("Error fetching images:", err));
  }, []);

  const slides = images.map(img => {
    return {
      id: img.id,
      src: img.url,
      width: img.width,
      height: img.height,
      srcSet: img.scaled.map(s => {
        return {
          width: s.width,
          height: s.height,
          src: s.url
        }
      })
    };
  });

  const restoreImage = (imageId) => {
    patch(`/api/uploads/${imageId}`, {hidden: false})
      .then(res => res.json())
      .then(data => {
          setImages(images.filter(image => image.id !== imageId));
      })
      .catch(err => console.error("Error fetching images:", err));
  };

  if (!isAdmin()) {
    return ( <h1>Permission deined</h1> );
  }

  return (
    <>
      <div className="container mt-5">
          <h2 className="mb-4">{t("hidden.images.title")}</h2>
          <PhotoAlbum
            layout="columns"
            photos={slides}
            onClick={({ index: current }) => setIndex(current)}
            renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
              <div className="img-container position-relative d-inline-block" >
                {renderDefaultPhoto({ wrapped: true })}
                <button title={t("hidden.images.restore")} onClick={() => restoreImage(photo.id)} className="btn btn-sm restore-btn position-absolute" style={{ top: 0, right: 0 }} >✓</button>
              </div>
            )}
          />
      </div>
    </>
  );
}

export default HiddenImages;

