import React, { useState } from "react";
import { useNavigate , BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


import { Button } from "react-bootstrap";
import UploadModal from "./UploadModal";
import { useAuth } from "./AuthContext";
import { getQueryParam } from "./utils";

const NavBar = ({ setUploadTrigger }) => {
  const { isLoggedIn, user, logout, isAdmin } = useAuth();

  const [showLoginModal, setShowLoginModal] = useState(false);
  const shouldShowUploadQueryParam = getQueryParam("show-upload");
  const shouldShowUpload = shouldShowUploadQueryParam && shouldShowUploadQueryParam.toLowerCase() === "true";
  const [showUploadModal, setShowUploadModal] = useState(shouldShowUpload);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const logOut = (
    isLoggedIn() && (
      <>
        <span className="navbar-text d-none d-md-inline" style={{ marginRight: '1em' }}>{user.sub}</span>

        <Nav.Link
          className="text-end custom-nav-link"
          style={{ width: 'auto' }}
          onClick={handleLogout}
        >
        {t("navbar.logout")}
        </Nav.Link>
      </>
    )
  );

  const uploadModal = (
    <>
    <Nav.Item>
      <Nav.Link className="text-end custom-nav-link" style={{ width: "auto" }} onClick={() => setShowUploadModal(true)}>
      {t("navbar.upload.images")}
      </Nav.Link>
    </Nav.Item>
    <UploadModal show={showUploadModal} onHide={() => setShowUploadModal(false)} setUploadTrigger={setUploadTrigger} />
    </>
  )

  const adminNav = (
    isAdmin() &&
    <>
      <Nav.Link className="text-end custom-nav-link" as={Link} to="/qr">{t("navbar.qr")}</Nav.Link>
      
      <Nav.Link className="text-end custom-nav-link" as={Link} to="/admin/hidden">{t("navbar.hidden.images")}</Nav.Link>
    </>
  );

  return (
    <Navbar collapseOnSelect expand="lg" className="custom-navbar" data-bs-theme="light">
      <Container>
        <Navbar.Brand as={Link} to="/">
            <img
              src="/static/tram-og-henrik-logo.png"
              height="100em"
              className="d-inline-block align-top"
              alt="T&H"
            />
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {isLoggedIn() &&
            <>
            <Nav className="me-auto">
              {uploadModal}
              {adminNav}
            </Nav>
            <Nav>
              {logOut}
            </Nav>
            </>
          }
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
