import React, { useState, useEffect, useRef, useCallback } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Download from "yet-another-react-lightbox/plugins/download";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import 'react-virtualized/styles.css';

import './Gallery.css'; // Add this line to include the custom styles
import DropzoneComponent from './Dropzone';
import { useAuth } from './AuthContext';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="dropdown-button-custom"
  >
    {children}
  </div>
));

const Gallery = ({ uploadTrigger, setUploadTrigger }) => {
  const [images, setImages] = useState([]);
  const [imageHeights, setImageHeights] = useState([]);
  const { isAdmin, get, patch } = useAuth();
  const gridRef = useRef(null);
  const thumbnailsRef = useRef(null);
  const zoomRef = useRef(null);
  const [index, setIndex] = useState(-1);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const { t } = useTranslation();

  const getColumnCount = (width) => {
    if (width <= 768) return 1; // Mobile devices
    if (width <= 1200) return 3; // Tablets
    return 4; // Desktops
  };
  const [columnCount, setColumnCount] = useState(getColumnCount(window.innerWidth));

  const calculateHeights = useCallback((images) => {
    const heights = images.map(image => {
      const aspectRatio = image.width / image.height;
      return (window.innerWidth / columnCount) / aspectRatio;
    });
    setImageHeights(heights);
    if (gridRef.current) {
      gridRef.current.resetAfterIndices({
        columnIndex: 0,
        shouldForceUpdate: true,
      });
    }
  }, [columnCount]);

  useEffect(() => {
    get("/api/uploads")
      .then(response => response.json())
      .then(data => {
        setImages(data.images);
        calculateHeights(data.images);
        setDropdownOpen(data.images.reduce((acc, _, idx) => ({ ...acc, [idx]: false }), {}));
      })
      .catch(error => {
        console.error(error);
      });
  }, [uploadTrigger, calculateHeights, get]);

  const handleResize = useCallback(() => {
    setColumnCount(getColumnCount(window.innerWidth));
    calculateHeights(images); // Recalculate heights on resize
  }, [setColumnCount, calculateHeights, images]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [images, handleResize]);

  const slides = images.map(img => {
    const filename = img.url.split("/").pop();
    return {
      id: img.id,
      src: img.url,
      width: img.width,
      height: img.height,
      downloadUrl: img.url,
      downloadFilename: filename,
      srcSet: img.scaled.map(s => {
        return {
          width: s.width,
          height: s.height,
          src: s.url
        }
      })
    };
  });

  const handleHideImage = (imageIndex, image) => {
    console.log('Hide image', image);
    patch(`/api/uploads/${image.id}`, {hidden: true})
      .then(res => res.json())
      .then(data => {
          setImages(images.filter(img => img.id !== image.id));
      })
      .catch(err => console.error("Error fetching images:", err));

    setDropdownOpen((prevState) => ({ ...prevState, [imageIndex]: false }));
  };

  const cellRenderer = ({ columnIndex, rowIndex, style }) => {
    const imageIndex = rowIndex * columnCount + columnIndex;
    if (imageIndex >= images.length) return null;

    const image = images[imageIndex];

    return (
      <div key={imageIndex} className="gallery-cell" style={{ ...style, padding: '5px' }}>
        <div>
          {isAdmin() && (
            <Dropdown
              show={dropdownOpen[imageIndex]}
              onToggle={() => setDropdownOpen((prevState) => ({ ...prevState, [imageIndex]: !prevState[imageIndex] }))}
              style={{ position: 'absolute', right: '5px' }}
              onClick={(e) => e.stopPropagation()}
            >
              <Dropdown.Toggle as={CustomToggle} id={`dropdown-custom-components-${imageIndex}`}>
                <ThreeDotsVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => { e.stopPropagation(); handleHideImage(imageIndex, image); }}>{t("gallery.hide.image")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <img src={image.url} alt={image.alt} className="gallery-image" onClick={() => setIndex(imageIndex)} />
        </div>
      </div>
    );
  };

  const getColumnWidth = () => window.innerWidth / columnCount;

  const getItemHeight = (rowIndex) => {
    const startIndex = rowIndex * columnCount;
    const endIndex = startIndex + columnCount;
    const rowHeights = imageHeights.slice(startIndex, endIndex);
    return Math.max(...rowHeights, 300); // Default height 300 if no heights calculated
  };

  return (
    <>
      <DropzoneComponent setUploadTrigger={setUploadTrigger } />
      <div style={{ height: '100vh', width: '100vw' }}>
        <Grid
          ref={gridRef}
          columnCount={columnCount}
          columnWidth={getColumnWidth}
          height={window.innerHeight}
          rowCount={Math.ceil(images.length / columnCount)}
          rowHeight={getItemHeight}
          width={window.innerWidth}
        >
          {cellRenderer}
        </Grid>

        <Lightbox
          index={index}
          slides={slides}
          open={index >= 0}
          close={() => setIndex(-1)}
          plugins={[Counter, Zoom, Download, Slideshow, Fullscreen]}
          counter={{ container: { style: { top: "unset", bottom: 0 } } }}
          thumbnails={{ ref: thumbnailsRef }}
          slideshow={{ autoplay: false, delay: 5000 }}
          on={{
            click: () => {
              (thumbnailsRef.current?.visible
                ? thumbnailsRef.current?.hide
                : thumbnailsRef.current?.show)?.();
            },
          }}
          zoom={{ ref: zoomRef }}
        />
      </div>
    </>
  );
};

export default Gallery;

