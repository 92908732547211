import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useAuth } from './AuthContext';
import { useTranslation } from 'react-i18next';

const DropzoneComponent = ({ setUploadTrigger }) => {
  const { getUrl, authToken } = useAuth();
  const [dragCounter, setDragCounter] = useState(0);

  const handleUpload = async (files) => {
    let formData = new FormData();
    for (let file of files) {
      formData.append("images", file);
    }

    try {
      await axios.post(getUrl("/api/uploads"), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${authToken}`
        },
      });
      setUploadTrigger(prev => !prev);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onDrop = useCallback((files) => {
    setDragCounter(0);
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    handleUpload(imageFiles);
  }, []);

  useEffect(() => {
    const handleDragEnter = (event) => {
      event.preventDefault();
      setDragCounter(current => current + 1);
    };

    const handleDragOver = (event) => {
      event.preventDefault();
      setDragCounter(current => current + 1);
    };

    const handleDragLeave = (event) => {
      event.preventDefault();
      setDragCounter(current => Math.max(0, current - 1));
    };

    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragleave', handleDragLeave);

    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragleave', handleDragLeave);
    };
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const { t } = useTranslation();
  const dropText = t('dropzone.drop.files');
  if (dragCounter === 0) return null;

  return (
    <div {...getRootProps()} className="dropzone-active" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, textAlign: 'center', paddingTop: '20%', fontSize: '24px' }}>
      <input {...getInputProps()} />
      <p>{dropText}</p>
    </div>
  );
};

export default DropzoneComponent;

