import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuth } from './AuthContext';

const UploadModal = ({ show, onHide, setUploadTrigger }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const { t } = useTranslation();

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const { getUrl, authToken } = useAuth();

  const uploadSuccessfulMessage = t("upload.success");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowProgressBar(true);
    setUploadProgress(0);
    setUploadStatus('');
    setIsUploading(true);

    const totalFiles = selectedFiles.length;
    let uploadedFiles = 0;

    try {
      for (let i = 0; i < totalFiles; i++) {
        let formData = new FormData();
        formData.append("files", selectedFiles[i]);

        await axios({
          method: "post",
          url: getUrl("/api/uploads"),
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${authToken}`
          },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(`File ${i + 1} progress: ${percentCompleted}%`); // Optional: log individual file progress
          },
        });

        uploadedFiles += 1;
        setUploadProgress(Math.round((uploadedFiles / totalFiles) * 100));
      }

      setUploadStatus(uploadSuccessfulMessage);
      setUploadTrigger(prev => !prev);
      setTimeout(() => {
        setUploadStatus(null);
        onHide();
      }, 3000);
    } catch (error) {
      console.error("Error:", error);
      setUploadStatus(t("upload.fail"));
    } finally {
      setShowProgressBar(false);
      setSelectedFiles([]);
      setIsUploading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("upload.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control type="file" multiple onChange={handleFileChange} />
          </Form.Group>
          <div className="ml-3">
            <Button disabled={isUploading || selectedFiles.length === 0} variant="light" style={{ marginTop: '1em' }} type="submit">{t("upload.submit")}</Button>
          </div>
        </Form>
        {showProgressBar && (
          <ProgressBar now={uploadProgress} striped animated label={`${uploadProgress}%`} className="mt-3" />
        )}
        {uploadStatus && <div className={`alert ${uploadStatus === uploadSuccessfulMessage ? 'alert-success' : 'alert-danger'} mt-3`}>{uploadStatus}</div>}
      </Modal.Body>
    </Modal>
  );
};

export default UploadModal;

