import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationNO from './locales/no/translation.json';
import translationEN from './locales/en/translation.json';
import translationVI from './locales/vi/translation.json';
import translationDE from './locales/de/translation.json';

const resources = {
  no: {
    translation: translationNO
  },
  en: {
    translation: translationEN
  },
  vi: {
    translation: translationVI
  },
  de: {
    translation: translationDE
  }
};

const browserLang = (navigator.language || navigator.userLanguage).split('-')[0]; // Extracts "en" from "en-US"
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: browserLang,
    fallbackLng: "no",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

