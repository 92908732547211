import React, { useEffect, useState, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { useAuth } from "./AuthContext";
import { getQueryParam, removeQueryParam } from "./utils";
import { useTranslation } from 'react-i18next';

const LoginPanel = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const { login, getUrl, isLoggedIn } = useAuth();
  const usernameRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoggedIn()) {
      usernameRef.current.focus();
    }

    const creds = getQueryParam("creds");
    if (creds) {
      const decoded = decodeBase64Json(creds);
      doLogin(decoded.username, decoded.password);
      removeQueryParam("creds");
    }
  }, []);

  const decodeBase64Json = (base64String) => {
    const jsonString = atob(base64String);
    const jsonObject = JSON.parse(jsonString);
    return jsonObject;
  };

  const doLogin = async (user, pass) => {
    setLoginError('');

    try {
      if (user.length === 0 || pass.length === 0) {
        throw new Error(t("login.blank.credentials"));
      }
      const response = await fetch(getUrl("/api/login"), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: user, password: pass}),
      });

      if (!response.ok) {
        throw new Error(t("login.fail"));
      }

      const { access_token } = await response.json();
      login(access_token);
    } catch (error) {
      setLoginError(error.message);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    await doLogin(username, password);
  };

  if (isLoggedIn()) {
    return null;
  }

  return (
    <div className="login-panel" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', maxWidth: '400px', backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <div className="login-panel-header" style={{ marginBottom: '20px' }}>
        <h2>{t("login.title")}</h2>
      </div>
      <div className="login-panel-body">
        {loginError && <div className="alert alert-danger" role="alert">{loginError}</div>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="username">
            <Form.Label>{t("login.username")}</Form.Label>
            <Form.Control 
              type="text" 
              placeholder={t("login.username")}
              ref={usernameRef}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>{t("login.password")}</Form.Label>
            <Form.Control 
              type="password" 
              placeholder={t("login.password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button variant="light" type="submit" style={{ marginTop: "1em" }}>
            {t("login.login")}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default LoginPanel;

