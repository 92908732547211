import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // convert to seconds
      if (decoded.exp < currentTime) {
        // Token has expired
        localStorage.removeItem('token');
        setUser(null);
      } else {
        setAuthToken(token);
        setUser(decoded);
      }
    }
  }, []);

  const handleFetch = (path, options = {}) => {
    const headers = {
      ...options.headers,
      "Authorization": `Bearer ${authToken}`
    };

    const updatedOptions = {
      ...options,
      headers
    };

    return fetch(getUrl(path), updatedOptions).then(response => {
      if (response.status === 401) {
        logout();
        return Promise.reject(new Error('Unauthorized'));
      }
      return response;
    }).catch(error => {
      console.error("An error occurred:", error);
      return Promise.reject(error);
    });
  };

  const login = (token) => {
    localStorage.setItem('token', token);
    setAuthToken(token);
    setUser(jwtDecode(token));
  };

  const logout = () => {
    localStorage.removeItem('token');
    setAuthToken(null);
    setUser(null);
  };

  const isLoggedIn = () => {
    return user !== null && user !== undefined;
  };

  const isAdmin = () => {
    return user && user.roles.includes("admin");
  };

  const getUrl = (path) => {
    if (process.env.NODE_ENV === "development") {
      return `http://localhost:8001${path}`;
    } else {
      return path;
    }
  };

  const get = (path) => {
    return handleFetch(path, { method: "GET" });
  };

  const post = (path, body) => {
    return handleFetch(path, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json",
      }

    });
  };

  const patch = (path, body) => {
    return handleFetch(path, {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json",
      }
    });
  }

  return (
    <AuthContext.Provider value={{ user, isLoggedIn, isAdmin, authToken, login, logout, get, post, patch, getUrl }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

