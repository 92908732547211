import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import "./App.css";
import Gallery from "./Gallery";
import LoginPanel from "./LoginPanel";
import HiddenImages from "./HiddenImages";
import NavBar from "./NavBar";
import QRCode from "./QRCode";
import { useAuth } from "./AuthContext";

const App = () => {
  const [uploadTrigger, setUploadTrigger] = useState(false);

  const { isLoggedIn } = useAuth();

  return (
    <>
      <NavBar setUploadTrigger={setUploadTrigger} />
      <Routes>
        {
          isLoggedIn() ?
          <>
            <Route path="/" element={ <Gallery uploadTrigger={uploadTrigger} setUploadTrigger={setUploadTrigger} /> } />
            <Route path="/admin/hidden" element={<HiddenImages />} />
            <Route path="/qr" element={<QRCode />} />
          </>
          :
          <>
            <Route path="/" element={ <LoginPanel /> } />
          </>
        }
      </Routes>
    </>
  );
}

export default App;
