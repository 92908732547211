import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';

const QRCodeComponent = () => {
  const [b64Image, setB64Image] = useState();

  const { get } = useAuth();

  useEffect(() => {
    get("/api/qr")
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw Error(res.statusText);
      })
      .then(data => {
        setB64Image(data.image);
      })
      .catch(err => console.error("Error fetching qr:", err));
  }, []);

  return (
    <div style={{ display: 'grid', placeItems: 'center', width: '100%', padding: '20px' }}>
      <img src={`data:image/png;base64,${b64Image}`} style={{ maxWidth: '100%', height: 'auto' }} alt="QR Code" />
    </div>
  );
};

export default QRCodeComponent;

